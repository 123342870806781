import { render, staticRenderFns } from "./StudyUnit.vue?vue&type=template&id=5856c7d5&scoped=true&lang=pug&"
import script from "./StudyUnit.vue?vue&type=script&lang=ts&"
export * from "./StudyUnit.vue?vue&type=script&lang=ts&"
import style0 from "./StudyUnit.vue?vue&type=style&index=0&id=5856c7d5&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5856c7d5",
  null
  
)

export default component.exports