

















import { Component, Prop, Mixins, Vue } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import StudyUnitInformation from '@/components/organisms/v2/StudyUnitInformation.vue'
import StudentBottomPanel from '@/components/organisms/v2/StudentBottomPanel.vue'
import { StudyHistory } from '@/models/api/v2/history/studyUnits'
import StudyUnitWebApi from '@/mixins/historyv2/StudyUnitWebApi'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'

@Component({
  components: {
    ButtonBase,
    SidebarSwitcher,
    StudyUnitInformation,
    StudentBottomPanel,
  },
})
export default class StudyUnit extends Mixins(StudyUnitWebApi, ClassModeChangeAble) {
  // curriculum_s_unit_id
  @Prop()
  id!: number

  private title = ''

  private get studentUserId() {
    return Vue.prototype.$cookies.get('authGdls').currentApiUserId
  }

  private studyUnitDatas: StudyHistory[] = []

  private async mounted(): Promise<void> {
    Vue.prototype.$loading.start()
    await this.checkClassModeAndSideMenuMode()
    await this.loadStudyHistories()
    Vue.prototype.$loading.complete()
  }

  private async loadStudyHistories() {
    const params = {
      userId: this.studentUserId,
      classModeCode: 'TJ,KS,AI',
      curriculumSUnitId: this.id,
      classCategoryCode: 'RK,EN',
    }

    const res = await this.loadStudyHistoriesApi(params)

    this.title = res.title
    this.studyUnitDatas = res.studyHistories
  }

  private async movePrev() {
    const name = Array.isArray(this.$route.query.prevPage)
      ? this.$route.query.prevPage[0] || ''
      : this.$route.query.prevPage || ''
    const selectedSubject = this.$route.query.selectedSubject ? String(this.$route.query.selectedSubject) : ''

    if (selectedSubject !== '') {
      this.$router.push({ name: name, query: { selectedSubject: selectedSubject } })
    } else {
      this.$router.push({ name: name })
    }
  }
}
